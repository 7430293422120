import { AbstractControl, ValidatorFn } from '@angular/forms';

export function creditCardExpirationValidator(): ValidatorFn {
  return (group: AbstractControl): { [key: string]: any } | null => {
    const monthControl = group.get('cardExpirationMonth');
    const yearControl = group.get('cardExpirationYear');

    if (monthControl && yearControl) {
      const selectedMonth = monthControl.value;
      const selectedYear = yearControl.value;

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      if (selectedYear < currentYear) {
        yearControl.setErrors({ expiredYear: true });
      } else {
        if (yearControl.hasError('expiredYear')) {
          yearControl.setErrors(null);
        }
      }

      if (selectedYear == currentYear && selectedMonth <= currentMonth) {
        monthControl.setErrors({ expiredMonth: true });
      } else {
        if (monthControl.hasError('expiredMonth')) {
          monthControl.setErrors(null);
        }
      }
    }

    return null;
  };
}
