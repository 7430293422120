//Angular inputs
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'app-scroll',
    templateUrl: './scroll.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollComponent
{
    @Output() scrollToTop = new EventEmitter<void>();

    constructor() { }

    onScrollToTop(): void {
        this.scrollToTop.emit();
    }
}