//Angular inputs
import { AfterContentInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'environments/environment';

//Model inputs
import { ScacApplicationConstants } from '../../constants/scac-application.constants';

//Service inputs
import { StateService } from '../../../shared/services/state-management/app-state-service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html'
})
export class BaseComponent implements OnInit, AfterContentInit, OnDestroy {
  //private/public properties
  appTitle : string = ScacApplicationConstants.AppTitle;
  hostName: string = window.location.hostname;
  envConfig: string = environment.configName;

  constructor(
    public pageTitle : Title,
    public metaTag: Meta,
    public currentState: StateService
  ) { }

  ngOnInit(): void {}

  ngAfterContentInit(): void {}

  ngOnDestroy(): void {}

}
