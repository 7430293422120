import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

interface Step {
  stepName: string;
  route: string;
}
@Injectable({
  providedIn: 'root',
})
export class StepService {
  private steps: Step[] = [{ stepName: '', route: '' }];
  private currentStepSubject: Subject<string> = new Subject<string>();
  currentStep$: Observable<string> = this.currentStepSubject.asObservable();

  constructor() {}

  setSteps(initialSteps: Step[]) {
    this.steps = initialSteps;
  }

  setCurrentStep(step: string): void {
    this.currentStepSubject.next(step);
  }

  getCurrentStep(): Observable<string> {
    return this.currentStepSubject.asObservable();
  }

  addStep(step: Step, position: number = 0) {
    this.steps.splice(position, 0, step);
  }

  removeStepByName(stepName: string) {
    this.steps = this.steps.filter((step) => step.stepName !== stepName);
  }

  getStepList(): Step[] {
    return this.steps;
  }
}
