export function getPaymentYears(numberOfYears: number = 10): number[] {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  for (let i = 0; i <= numberOfYears; i++) {
    years.push(currentYear + i);
  }
  return years;
}

export function getMonths(): string[] {
  return Array.from({ length: 12 }, (_, index) => String(index + 1).padStart(2, '0'));
}