import { AfterContentInit, Component, ContentChild, Input, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { ErrorMessages } from 'app/shared/services/error-messages/error-messages.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent implements OnInit, AfterContentInit {

  @Input() label: any;
  @Input() class: string;
  @Input() toolTip: string;

  @ContentChild(NgControl) NgControl: NgControl;

  isRequired: boolean = false;
  private subscriptions = new Subscription();
  errorMessages: { [key: string]: string | ((error: any) => string) } = {};
  
  constructor(
    private errorMessagesService: ErrorMessages
  ) { 
    this.subscriptions.add(this.errorMessagesService.getErrorMessages().subscribe((messages) => {
      this.errorMessages = messages;
    }));
  }

  get formControl() {
   return this.NgControl?.control; 
  }

  ngOnInit() {
  }
  
  ngAfterContentInit(): void {
    this.checkIfRequired();
    if (this.formControl) {
      this.subscriptions.add(this.formControl.statusChanges.subscribe(() => {
        this.checkIfRequired();
      }));
    }
  }

  checkIfRequired() {
    if (!this.formControl) {
      return;
    }
  
    const validators = this.formControl.validator ? [this.formControl.validator] : [];
    this.isRequired = validators.some(validator => {
      const validationError = validator({} as AbstractControl);
      return validationError && validationError['required'];
    });
  }

  getFormErrors(): string[] {
    if (this.formControl && this.formControl.errors) {
      return Object.keys(this.formControl.errors);
    }
    return [];
  }

  getErrorMessage(errorKey: string): string {
    const error = this.formControl?.errors?.[errorKey];
    const defaultMessage = this.errorMessages[errorKey];

    if (typeof defaultMessage === 'function') {
      return defaultMessage(error);
    }

    return defaultMessage || `Error: ${errorKey}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
