<div class="row d-flex m-0 mt-4 justify-content-center step-row">
  <div class="step-progress-container">
    <div class="progress w-100 p-0">
      <div
        class="progress-bar step-progress-bar"
        role="progressbar"
        [ngStyle]="{ width: progressBar + '%' }"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="step-progress-container">
    <div class="d-flex justify-content-between p-0">
      <ng-container *ngFor="let step of steps; let i = index">
        <div class="step-container p-0 align-items-top">
          <span
            class=""
            [ngClass]="i > stepIndex ? 'step-counter' : 'step-ounter-active'"
            >{{ i + 1 }}</span
          >
          <mat-icon
            *ngIf="step.route === currentStep; else unchecked"
            aria-hidden="false"
            aria-label="step icon"
            fontIcon="circle"
            class="step-color-checked"
            >circle</mat-icon
          >
          <ng-template #unchecked>
            <mat-icon
              aria-hidden="false"
              aria-label="step icon unchecked"
              fontIcon="circle"
              [ngClass]="
                i > stepIndex ? 'step-color-unchecked' : 'step-color-checked'
              "
              >circle</mat-icon
            >
          </ng-template>
        </div>
      </ng-container>
    </div>

  </div>
</div>
<div class="d-flex justify-content-center step-title-container">
  <div class="row justify-content-between p-0 step-title-row">
    <ng-container *ngFor="let step of steps; let i = index">
      <div
        class="step-title-col p-0 text-center  align-items-top justify-content-center"
      >
        <span
          [ngClass]="
            step.route === currentStep ? 'step-title-active' : 'step-title'
          "
          >{{ step.stepName }}</span
        >
      </div>
    </ng-container>

  </div>
</div>
