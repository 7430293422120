import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Injectable()
export class ScacApiInterceptor implements HttpInterceptor {

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const accessToken = this.oktaAuth.getAccessToken();
      let headers = req.headers;

      if (req.headers.has('Authorization')) {
        // Remove the Authorization header if it exists
        headers = req.headers.delete('Authorization');
      }

      if (accessToken && accessToken?.trim() !== '') {
        headers = req.headers.append('Authorization', `Bearer ${accessToken}`)

        req = req.clone({
          headers: headers
        });
      }
    }
    catch (err: any) {
      // Do not transform request, just send like normal
    }

    return next.handle(req);
  }

}