export const UsersConstants = {
  baseUsersURL: 'api/Users'
}

export const RolesUserConstant = {
  baseRolesUserURL: 'api/RolesUser'
}

export const DataProductConstant = {
  dataProductBaseURL: 'dataproducts'
}