//Angular inputs
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { environment } from '../environments/environment';

//Custom module input
import { AppRoutingModule } from './app-routing.module';
import { NavModule } from './common/nav.module';
import { DialogsModule } from './shared/dialogs/dialogs.module';
import { ScacLoadingSpinnerModule } from './shared/components/scac-loading-spinner/scac-loading-spinner.module';
import { ScacApiInterceptor } from './shared/interceptors/scac-api.interceptor';

//Mat inputs
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

//Custom component inputs
import { AppComponent } from './app.component';
import { BaseComponent } from './shared/components/base/base.component';

const oktaConfig: OktaAuthOptions = {
  issuer: environment.oktaIssuer,
  clientId: environment.oktaClientId,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email','groups','groups_okta_users','org_info','offline_access'],
  responseType: 'code',
  pkce: true,
};

let oktaAuth = new OktaAuth(oktaConfig);

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent
  ],
  imports: [
    //Angular inputs
    NgxMaskModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient]
      }
    }),
    OktaAuthModule,

    //Custom module input
    ScacLoadingSpinnerModule,
    AppRoutingModule,
    DialogsModule,
    NavModule,
    
    //Mat inputs
    MatProgressSpinnerModule
  ],
  providers: [
    Title,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: HTTP_INTERCEPTORS, useClass: ScacApiInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
