import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'alert-dialog',
  templateUrl: 'alert-dialog.component.html',
})
export class AlertDialog {
  closeButtonText = "Close";
  message: string = "Alert info";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertDialog>) {
    if(data){
    this.message = data.message || this.message;
    if (data.buttonText) {
      this.closeButtonText = this.closeButtonText;
    }
      }
  }

  onClose(): void {
    this.dialogRef.close(true);
  }

}