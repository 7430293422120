import { environment } from "environments/environment";

export const ScacApplicationConstants = {
  Expired: 'Expired',
  Denied: 'Denied',
  Pending: 'Pending',
  Approved: 'Assigned',
  Cancelled: 'Cancelled',
  ScacApp: 'scacAppKey',
  AppTitle: environment.appTitle,
  RequestorId: 'RequestorId'
}
