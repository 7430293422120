//Angular inputs
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter, skip } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'environments/environment';

//Service inputs
import { StateService } from './shared/services/state-management/app-state-service';
import { ApiService } from './shared/services/api/api.service';
import { MaintenanceGuardService } from './shared/services/guard/maintenance-guard.service';

//Custom inputs
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  //private/public properties
  currentUser: any = null;
  authed: boolean = false;
  role: string = '';

  constructor(
    private router: Router,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private meta: Meta,
    private title: Title,
    private translate: TranslateService,
    public stateService: StateService,
    public api: ApiService,
    public authStateService: OktaAuthStateService,
    public maintenanceService: MaintenanceGuardService
  ){
    translate.addLangs(['en', 'es', 'fr']);
    translate.setDefaultLang('en');
    this.initCredentials();
  }

  ngOnInit(): void {
    this.addAnalyticsScriptsInHead();
    this.title.setTitle(environment.appTitle);
    this.meta.addTags([
      { name: 'keywords', content: 'National Motor Freight Traffic Association, NMFTA, SCAC® Code, Standard Carrier Alpha Code™' },
      { name: 'robots', content: 'index, follow' },
      { name: 'writer', content: '2022@ NMFTA' },
      { charset: 'UTF-8' }
    ]);

    this.maintenanceService.getIsMaintenanceMode().subscribe();
  }

  initCredentials() {
    this.authStateService.authState$.pipe(skip(1)).subscribe(isAuthed => {
      if (isAuthed.isAuthenticated) {
        this.authed = isAuthed.isAuthenticated as boolean;
        if (this.stateService.getActingUser()) {
          this.currentUser = this.stateService.getActingUser();
          this.stateService.setRoleDirect(from([this.currentUser.roles[0]]) as Observable<string>);
          this.stateService.setAuth(from([true]) as Observable<boolean>);
          this.setUserData(this.currentUser);
          this.stateService.setCurrentUser(this.currentUser);
        } else {
          this.oktaAuth.getUser().then(oktaUser => {
            this.api.getUserByEmail(oktaUser.email as string).subscribe((user) => {
              this.stateService.setRoleDirect(from([user.roles![0]]) as Observable<string>);
              this.stateService.setAuth(from([true]) as Observable<boolean>);
              this.setUserData(user);
              this.stateService.setCurrentUser(user);
              this.stateService.clearActingUser();
          });
          });
        }
      } else {
        this.stateService.setRoleDirect(from(['Visitor']) as Observable<string>);
        this.stateService.setAuth(from([false]) as Observable<boolean>);
      }
    });
  }

  setUserData(user: any) {
    this.currentUser = user;
    this.role = user.roles[0];
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  initializeAnalyticsSetup() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', environment.googleAnalyicsTrackId, { page_path: event.urlAfterRedirects } );
      }
    );
  }

  addAnalyticsScriptsInHead() {
    let gaTrackId = environment.googleAnalyicsTrackId;
    let gTagScript: HTMLScriptElement = document.createElement('script');
    gTagScript.async = true;
    gTagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackId;
    // append script at end of Head tag
    document.head.append(gTagScript);

    //add script for gtag function layer
    let gTagFunctionScript = document.createElement('script');
    gTagFunctionScript.innerHTML = `
      {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', ${JSON.stringify(gaTrackId)});
      }
    `;
    document.head.append(gTagFunctionScript);

    // Once script tag prepare initialize the setup
    this.initializeAnalyticsSetup();
  }

  ngOnDestroy(){
    this.stateService.clearSession();
  }
}
