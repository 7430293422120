//Angular inputs
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

//Service inputs
import { RepositoryService } from '../repository/repository.service';
import { StateService } from '../state-management/app-state-service';
import { HttpHeaders } from '@angular/common/http';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  viewUserEventDispatcher = new BehaviorSubject<boolean>(false);

  constructor(
    private repository: RepositoryService, 
    private stateService: StateService, 
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) { }

  emitViewUserEvent(isView: boolean) {
    this.viewUserEventDispatcher.next(isView);
  }

  getViewUserEventEmitter() {
    return this.viewUserEventDispatcher;
  }

  getSearchedUsers(searchString: string): Observable<string> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.repository.get(environment.apiBaseURL, 'api/Users/autocomplete/' + searchString, null, null, headers);
  }
}
