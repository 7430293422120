//Angular inputs
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { Observable, of } from 'rxjs';

//Model inputs
import { Users } from '../../models/users.model';
import { GLAccount } from 'app/shared/models/glAccount.model';
import { Payload } from 'app/shared/models/payload.model';
import { UmCountries } from 'app/shared/models/umCountries.model';
import { FilterRequest } from 'app/shared/models/filterRequest.model';
import { UsersConstants } from '../../constants/users.constants';
import { RolesConstants } from 'app/shared/services/roles.constants';

//Service inputs
import { RepositoryService } from './../repository/repository.service';
import { BaseService } from '../../services/base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseService{
  private countries$: Observable<UmCountries[]>;
  countries: UmCountries[];

  constructor(
    private http : HttpClient,
    private repository: RepositoryService
  ) {
    super(http)
  }

  headers = new HttpHeaders()
    .set("Content-Type", "application/json; charset=utf-8");

  // #region User
  postUser(data:Users) {
    return this.repository.post(environment.apiBaseURL, UsersConstants.baseUsersURL, JSON.stringify(data), this.headers);
  }

  public getCountries(): Observable<UmCountries[]> {
    let countryList = sessionStorage.getItem('countries')
    if(countryList == null) {
      
      return new Observable((observer) => {
        this.repository.get(environment.apiBaseURL, 'misc/getcountries')
        .subscribe(x => { 
            let list: UmCountries[] = x;
            this.countries$ = of(list);
            sessionStorage.setItem('countries',JSON.stringify(x))
            observer.next(list);
            observer.complete();
        })
      });
    }
    else
    {
      let list: UmCountries[] = JSON.parse(countryList);
      this.countries$ = of(list);
      return this.countries$;

    }
  }

  public getUmCountryCode(countryCode : string | undefined): string{
    if(countryCode === undefined)
      return "";
    if(countryCode.length == 3)
      return countryCode;

    let query = this.countries.filter(x => x.scacCountryCode === countryCode);
    return query[0].umCountryCode;
  }

  getGLAccounts() {
    return this.http.get<GLAccount[]>(environment.apiBaseURL + 'scac/GetGLCodes');
  }

  getUsers() {
    return this.http.get<any>(environment.apiBaseURL + UsersConstants.baseUsersURL, {headers: this.headers});
  }
  
  userExists(email: string): Observable<boolean> {
    return this.repository.post(environment.apiBaseURL, UsersConstants.baseUsersURL + `/exists/`, `{"email":"${email}"}`, this.headers); //`{"email":"${email}"}`, this.headers);
  }

  // getUserByEmail(email: string): Observable<Users> {
  //   return this.http.get<any>(environment.apiBaseURL + UsersConstants.baseUsersURL + `/byemail/${encodeURIComponent(email)}`, {headers: this.headers});
  // }

  getUserByEmail(email: string): Observable<Users> {
    return this.repository.post(environment.apiBaseURL, UsersConstants.baseUsersURL + `/userbyemail/`, `{"email":"${email}"}`, this.headers);
  }

  getUsersPaged(filter: string, sortColumn: string, sortDirection: string, page: number, pageSize: number, routeRequestorId?: string, loggedInRequestorId?: string, usecase?: string): Observable<Payload> {
    var filterRequest = new FilterRequest();
    filterRequest.filter = filter;

    const qryStr = `/searchUsersPaged?usecase=${usecase}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&page=${page}&pageSize=${pageSize}&routeRequestorId=${routeRequestorId}&loggedInRequestorId=${loggedInRequestorId}`
    return this.repository.post(environment.apiBaseURL,  UsersConstants.baseUsersURL + qryStr, filterRequest, this.headers);
  }

  putUser(data:any) {
    return this.repository.put(environment.apiBaseURL, UsersConstants.baseUsersURL, JSON.stringify(data));
  }

  deleteUser(data: string) {
    return this.repository.delete(environment.apiBaseURL, UsersConstants.baseUsersURL + `/${data}`, '');
  }

  resetUserPassword(email: string) {
    return this.repository.post(environment.apiBaseURL,`${UsersConstants.baseUsersURL}/ResetPassword/${email}` , '', this.headers);
  }

  getUserByRequestorId(data: string) {
    return this.http.get<any>(environment.apiBaseURL + UsersConstants.baseUsersURL + `/byrequestor/${data}`, {headers: this.headers});
  }

  getRoles() {
    return this.http.get<any>(environment.apiBaseURL + RolesConstants.baseRolesURL);
  }

  putRolesUser(data:any){
    return this.repository.put(environment.apiBaseURL, RolesConstants.baseUserRolesURL, JSON.stringify(data));
  }

}
