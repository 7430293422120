<h1 mat-dialog-title class="text-center">Confirmation</h1>

<mat-dialog-content>
	<p>
		{{message}}
	</p>
     <label>{{reasonMessage}} <span class="required-field">*</span></label>
	 <input class="form-control w-75" type="text" uppercase id="confirmReason" [(ngModel)]="confirmReason"/>
	 <br>
</mat-dialog-content>

<mat-dialog-actions align="center">
	<button class="btn text-bg-nmftaprimary rounded-pill me-2" (click)="onConfirmClick()" tabindex="1" [disabled]="confirmReason == ''">{{confirmButtonText}}</button>
	<button class="btn text-bg-nmftasecondary rounded-pill" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>