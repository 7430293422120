import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { OktaCallbackComponent } from "@okta/okta-angular";

@Component({
  selector: 'app-custom-okta-callback',
  template: '<span></span>',
})
export class CustomCallbackComponent extends OktaCallbackComponent {
  private router = inject(Router);

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    if (this.error?.includes('AuthSdkError')) {
      this.router.navigate(['/']);
    }
  }
}