<div class="input-wrapper {{ class }}">
  <label class="active label-input-field" *ngIf="label; else blankLabel">
    {{ label | translate}}
    <span *ngIf="isRequired" class="required-field-indicator">*</span>&nbsp;
    <span *ngIf="toolTip"
      class="badge rounded-circle text-bg-primary badge-info" 
      [matTooltip]="toolTip | translate" 
      matTooltipPosition="above"
    >?</span>
  </label>
  <ng-template #blankLabel>
    <label class="active label-input-field">
      &nbsp;
    </label>
  </ng-template>
  <ng-content></ng-content>
  @if (formControl?.errors && formControl?.touched) {
    @for (error of getFormErrors(); track error) {
      <div class="error-input-message">
        {{ getErrorMessage(error) }}
      </div>
    }
  }
</div>