export class PaymentPayload {
    clientReferenceInformationCode: string = "";
    isACH: boolean = false;
    bankAccountNumber: string = "";
    bankAccountType: string = "";
    bankAccountOwner: string = "";
    bankRoutingNumber: string = "";
    cardNumber: string = "";
    cardExpirationMonth: string = "";
    cardExpirationYear: string = "";
    cardCVV: string = "";
    checkNumber: string = "";
    firstName: string = "";
    lastName: string = "";
    address1: string = "";
    city: string = "";
    state: string = "";
    postalCode: string = "";
    country: string = "";
    currency: string = "USD";
    phone: string;
    email: string;
    totalAmount: string;
    glCode: string = "";
    originatingPartyName: string = "";
    wireIdentifier: string = "";
    eulaAgreed?: boolean;
    paymentMethod: number;
}