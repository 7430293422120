//Angular inputs
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService extends BaseService{

  constructor(
    private http: HttpClient
  ) {
    super(http)
  }

  public get(url: string, endpoint: string, responseType?: any, params?: any, headers?: HttpHeaders): Observable<any> {
    let reqParams: HttpParams = new HttpParams();
    if (params) {
      reqParams = new  HttpParams();
      Object.keys(params).forEach(key => {
        reqParams = reqParams.append(key, params[key]);
      });
    }
    return this.handleRequest('Get', url + endpoint, null, reqParams, headers, responseType);
  }

  public post(url: string, endpoint: string, body: any, reqHeaders?: HttpHeaders, responseType?: any): Observable<any> {
    return this.handleRequest('Post', url + endpoint, body, undefined, reqHeaders, responseType);
  }

  public upload(url: string, endpoint: string, formData: any, reportProgress = false): Observable<any> {
    const httpObserve: any = reportProgress ? 'events' : 'response';
    const httpOptions  = {
      reportProgress,
      observe : httpObserve
    };

    return this.http.post(url + endpoint, formData, httpOptions);
  }

  public put(url: string, endpoint: string, body: any): Observable<any> {
    const httpObserve = 'response';
    const httpResponseType = 'json';
    let setHeaders = new HttpHeaders();
    setHeaders = setHeaders.set('Content-Type', 'application/json');
    setHeaders = setHeaders.set('Access-Control-Allow-Origin', '*');

    const httpOptions  = {
      body,
      headers: setHeaders,
      responseType: httpResponseType,
      observe : httpObserve
    };
    let fullUrl = url + endpoint;
    return this.getHttpRequest('Put', fullUrl, httpOptions);
  }

  public delete(url: string, endpoint: string, body: any): Observable<any> {
    let reqHeaders =  new HttpHeaders();
    reqHeaders = reqHeaders.set('Content-Type', 'application/json');
    reqHeaders = reqHeaders.set('Access-Control-Allow-Origin', '*');

    const httpOptions  = {
      headers: reqHeaders,
      body,
    };
    return this.getHttpRequest('Delete', url + endpoint, httpOptions);
  }

  private handleRequest(method: string, 
                        url: string, 
                        reqBody?: any,
                        reqParams?: HttpParams, 
                        reqHeaders?: HttpHeaders, 
                        responseType?: any): Observable<any> {
    const httpObserve: any = 'response';
    const httpResponseType = responseType ? responseType : 'json';
    if (reqHeaders) {
      reqHeaders = reqHeaders.set('Content-Type', 'application/json');
      reqHeaders = reqHeaders.set('Access-Control-Allow-Origin', '*');
    }
    else{
      reqHeaders =  new HttpHeaders({
        'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin': '*'
      });
    }

    const httpOptions  = {
      body : reqBody,
      headers: reqHeaders,
      params: reqParams,
      responseType: httpResponseType,
      observe : httpObserve
    };

    return this.getHttpRequest(method, url, httpOptions);
  }

  private getHttpRequest(method: string, url: string, httpOptions: any): Observable<any> {
    return new Observable((observer) => {
      this.http.request(method, url, httpOptions)
        .subscribe(
        (response: any) => {
          const resBody = (response !== null && response['body'] !== null ) ? response['body'] : {};
          observer.next(resBody);
          observer.complete();
        },
        (error) => {
          console.log('[error]', error);
          observer.error(error);
        });
    });
  }
}
