import {AbstractControl, ValidationErrors} from '@angular/forms';

export function phoneValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value === '') {
    return null;
  }

  const regex = /^(?:\+?(\d{1,3}))?[\s.-]?\(?(\d{1,4})\)?[\s.-]?(\d{1,4})[\s.-]?(\d{1,4})(?:\s*(x|X|ext|EXT|extension|Extension)\s*(\d+))?$/;
  return regex.test(control.value) ? null : { invalidPhone: true };
}
