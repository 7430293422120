<app-newsfeed></app-newsfeed>
<nav class="navbar navbar-expand-xl navbar-light px-5">
  <div class="container nav-container  w-100">
    <div class="justify-content-between d-flex">

      <a class="navbar-brand" [routerLink]="['']">
        <img src="/assets/scac-logo.svg" class="scac-logo">
      </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false"
        aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div>
      <div class="collapse navbar-collapse nav-bar-login-row" id="navbarNavAlt">
        <div class="container login-column">
          <ul class="navbar-nav d-flex justify-content-end py-lg-0 py-sm-2 ml-auto">
            <li class="align-items-center nav-item d-flex">
              <ng-container *ngIf="authed">
                <button mat-button class="has-icon h-100 gap-2" [matMenuTriggerFor]="userProfile"
                  #openUser="matMenuTrigger">
                  <img src="assets/user_logo.png" class="user-logo pe-2">
                  {{ currentUser['firstName'] + ' ' + currentUser['lastName'] }}
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #userProfile="matMenu" xPosition="before">
                  <div class="px-2 pb-1">{{ currentUser['firstName'] + ' ' + currentUser['lastName'] }}<span> [{{ role
                      }}]</span></div>
                  <button *ngIf="permissions.hasCustomerSvcAccess(role) && !stateService.getActingUser()" mat-menu-item
                    [routerLink]="['/admin/view-as-user']">
                    <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                      <div class="nav-menu-icon"><img src="assets/icons/view-as-user.png"></div> View as User
                    </div>
                  </button>
                  <button *ngIf="stateService.getActingUser()" mat-menu-item (click)="resumeLoggedInUser()">
                    <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                      <div class="nav-menu-icon"><img src="assets/icons/view-as-user.png"></div> Resume logged-in User
                    </div>
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                      <div class="nav-menu-icon"><img src="assets/icons/logout.png"></div> Logout
                    </div>
                  </button>
                </mat-menu>
              </ng-container>

              <ng-container *ngIf="!authed">
                <button mat-button (click)="login()">Login</button>
              </ng-container>

              <select class="ml-2 form-control language-selector" #selectedLang
                (change)="switchLang(selectedLang.value)">
                <option *ngFor="let language of languageList" [value]="language.id"
                  [selected]="language.id === translate.currentLang">
                  {{ language.name }}
                </option>
              </select>
            </li>

          </ul>
        </div>
      </div>

      <div class="collapse navbar-collapse justify-content-end gap-2 h-100" [ngClass]="{ 'show': !isCollapsed }">

        <ul class="navbar-nav d-flex justify-content-end py-lg-0 py-sm-2">

          <ng-container *ngIf="!authed">
            <li class="nav-item">
              <button mat-button [routerLink]="['']">Home</button>
            </li>
            <li class="nav-item">
              <button mat-button [routerLink]="['/renew-check-status']">Renew/Check Status</button>
            </li>
            <li class="nav-item">
              <button mat-button [routerLink]="['/reseller/registration']">Reseller Registration</button>
            </li>
            <li class="nav-item">
              <button mat-button (click)="redirectToApplyPage()">Apply for SCAC</button>
            </li>
            <li class="nav-item">
              <button mat-button [routerLink]="['/data-products-purchase']">Purchase Data Product</button>
            </li>
          </ng-container>

          <ng-container *ngIf="authed">
            <li class="nav-item">
              <button mat-button (click)="navigateUser()">Home</button>
            </li>
            <li class="nav-item">
              <button mat-button [routerLink]="['/renew-check-status']">Renew/Check Status</button>
            </li>
            <li class="nav-item"
                *ngIf="isAdmin">
              <button mat-button class="has-icon" [matMenuTriggerFor]="admin" #openAdmin="matMenuTrigger">
                Admin
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #admin="matMenu" xPosition="before">
                <button
                  *ngIf="(!permissions.hasBusinessAccess(role) && !permissions.hasCCPAccess(role)) || role === 'Super Admin'"
                  mat-menu-item [routerLink]="['/admin/manage-accounts']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/manage-accounts.png"></div>
                    Manage Accounts
                  </div>
                </button>
                <button
                  *ngIf="(!permissions.hasBusinessAccess(role) && !permissions.hasCCPAccess(role)) || role === 'Super Admin'"
                  mat-menu-item [routerLink]="['/admin/manage-users']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/manage-users.png"></div>
                    Manage Users
                  </div>
                </button>
                <button *ngIf="permissions.hasCustomerSvcAccess(role) || role === 'Super Admin'" mat-menu-item
                  [routerLink]="['/admin/manage-carriers']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/apply.png"></div>
                    Manage Carriers
                  </div>
                </button>

                <button mat-menu-item *ngIf="role === 'Super Admin' || permissions.hasCustomerSvcMgrAccess(role)"
                  [routerLink]="['/admin/manage-subscriptions']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/manage-orders.png" /></div>
                    Manage Subscriptions
                  </div>
                </button>

                <button
                  *ngIf="permissions.hasBicRailAccess(role) || permissions.hasCustomerSvcNoExt(role) || role === 'Super Admin'"
                  mat-menu-item [routerLink]="['/admin/manage-reservations']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/reservation.png"></div>
                    Manage Reservations
                  </div>
                </button>
                <button mat-menu-item *ngIf="permissions.hasInternalAccess(role)"
                  [routerLink]="['/admin/manage-products']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/manage-orders.png" /></div>
                    Manage Products
                  </div>
                </button>

                <button mat-menu-item *ngIf="permissions.hasCustomerSvcMgrAccess(role) || role === 'Super Admin'"
                  [routerLink]="['/admin/manage-fees']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/fees.png" /></div>
                    Manage Fees Definitions
                  </div>
                </button>

                <button mat-menu-item
                  *ngIf="permissions.hasMarketingAccess(role) || permissions.hasCustomerSvcNoExt(role) || role === 'Super Admin'"
                  [routerLink]="['/admin/manage-newsfeed']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/newsfeed.png" /></div>
                    Manage Newsfeed
                  </div>
                </button>
                <button *ngIf="permissions.hasCustomerSvcAccess(role)" mat-menu-item
                  [routerLink]="['/admin/manage-expired-scacs']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/history.png" /></div>
                    Manage Expired Codes
                  </div>
                </button>
              </mat-menu>
            </li>
            <li class="nav-item"
                *ngIf="role === 'Reseller'">
              <button mat-button class="has-icon" [matMenuTriggerFor]="reseller" #openReseller="matMenuTrigger">
                Reseller
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #reseller="matMenu" xPosition="before">
                <button
                  mat-menu-item [routerLink]="['/reseller/manage-accounts']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/manage-accounts.png"></div>
                    Manage Accounts
                  </div>
                </button>
                <button
                  mat-menu-item [routerLink]="['/reseller/manage-users']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/manage-users.png"></div>
                    Manage Users
                  </div>
                </button>
              </mat-menu>
            </li>

            <li class="nav-item">
              <button mat-button class="has-icon" [matMenuTriggerFor]="manageSCAC" #openScac="matMenuTrigger">
                Manage SCAC<sup>&reg;</sup>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #manageSCAC="matMenu" xPosition="before">
                <button *ngIf="permissions.hasCustomerSvcAccess(role)" mat-menu-item
                  [routerLink]="['/scac/application-search']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/search.png"></div>
                    Applications
                  </div>
                </button>
                <!--<button *ngIf="(permissions.hasBorderAccess(role) || permissions.hasPartnerCodeAccess(role) || permissions.hasCustomerSvcAccess(role)) && currentUser.scacOnline" mat-menu-item [routerLink]="['/scac/scac-search']">-->
                <!-- <button *ngIf="permissions.hasBicRailAccess(role) || permissions.hasCustomerAccess(role) || permissions.hasResellerAccess(role) || permissions.hasBorderAccess(role) || permissions.hasPartnerCodeAccess(role) || permissions.hasCustomerSvcAccess(role) || currentUser.scacOnline" mat-menu-item [routerLink]="['/scac/scac-search']"> -->
                <button mat-menu-item [routerLink]="['/scac/scac-search']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/search.png"></div>
                    SCAC<sup>&reg;</sup> Online
                  </div>
                </button>
                <button *ngIf="permissions.hasCustomerSvcAccess(role) || permissions.hasCCPRRRAccess(role) || permissions.hasBicRailAccess(role)" mat-menu-item
                  [routerLink]="['/scac/scac-dds']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/download.svg"></div>
                    SCAC DDS
                  </div>
                </button>
                <button *ngIf="permissions.hasCustomerSvcAccess(role) || permissions.hasCCPRRRAccess(role) || permissions.hasBicRailAccess(role)" mat-menu-item
                [routerLink]="['/scac/scac-api']">
                <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                  <div class="nav-menu-icon"><img src="assets/icons/gear-blue.png"></div>
                  SCAC API
                </div>
              </button>
                <button *ngIf="permissions.hasCustomerSvcAccess(role) || permissions.hasCCPRRRAccess(role)"
                  mat-menu-item (click)="redirectToApplyPage()">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/apply.png"></div>
                    Apply for SCAC
                  </div>
                </button>
                <button *ngIf="!permissions.hasBusinessAccess(role) || role === 'Super Admin'" mat-menu-item
                  [routerLink]="['/data-products-purchase']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/products.png"></div>
                    Purchase Data Product
                  </div>
                </button>
              </mat-menu>
            </li>

            <li class="nav-item">
              <button mat-button class="has-icon" [matMenuTriggerFor]="reports" #openReports="matMenuTrigger">
                Reports
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #reports="matMenu" xPosition="before">
                <button *ngIf="permissions.hasInternalAccess(role)" mat-menu-item
                  [routerLink]="['/reports/user-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/report-account.png" /></div>
                    Account Report
                  </div>
                </button>
                <button *ngIf="permissions.hasInternalAccess(role)" mat-menu-item
                  [routerLink]="['/reports/scac-history-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/audit-logs.png" /></div>
                    SCAC History
                  </div>
                </button>
                <button *ngIf="permissions.hasCustomerSvcAccess(role) || permissions.hasCCPRRRAccess(role)"
                  mat-menu-item [routerLink]="['reports/renewal-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                    Renewal Report
                  </div>
                </button>
                <button *ngIf="permissions.hasCustomerSvcAccess(role)" mat-menu-item
                  [routerLink]="['reports/partner-code-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                    Partner Codes Report
                  </div>
                </button>
                <!--<button *ngIf="permissions.hasFinanceAccess(role)" mat-menu-item [routerLink]="['reports/financial-report']">
                <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button"><div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                Financial Report
               </button>
               <button *ngIf="permissions.hasFinanceAccess(role)" mat-menu-item [routerLink]="['reports/sales-report']">
                <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button"><div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                Sales Report
               </button>
               <button *ngIf="permissions.hasFinanceAccess(role)" mat-menu-item [routerLink]="['reports/reconciliation-report']">
                <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button"><div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                Reconciliation Report
               </button>-->
                <button *ngIf="permissions.hasInternalAccess(role)" mat-menu-item
                  [routerLink]="['reports/payment-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                    Payment Report
                  </div>
                </button>
                <button *ngIf="permissions.hasDailyReportAccess(role)" mat-menu-item
                  [routerLink]="['reports/daily-scac-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                    Daily Scac Report
                  </div>
                </button>
                <button *ngIf="permissions.hasDailyReportAccess(role)" mat-menu-item
                  [routerLink]="['reports/finance-fy-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                    Finance FY Renewals Report
                  </div>
                </button>
                <button *ngIf="permissions.hasFinanceAccess(role)" 
                        mat-menu-item
                        [routerLink]="['reports/scac-summary-report']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                  <div class="nav-menu-icon"><img src="assets/icons/report-renewal.png" /></div>
                    Transaction Activity Summary
                  </div>
                </button>
              </mat-menu>
            </li>
          </ng-container>

          <li class="nav-item">
            <a href="https://nmfta.org/support/" target="_blank">
              <button mat-button>Contact Support</button>
            </a>
          </li>

          <li class="nav-item nav-item-hide">
            <ng-container *ngIf="authed">
              <button mat-button class="has-icon" [matMenuTriggerFor]="userProfile" #openUser="matMenuTrigger">
                <img src="assets/user_logo.png" class="user-logo">
                {{ currentUser['firstName'] + ' ' + currentUser['lastName'] }}
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #userProfile="matMenu" xPosition="before">
                <div class="px-2 pb-1">{{ currentUser['firstName'] + ' ' + currentUser['lastName'] }}<span> [{{ role
                    }}]</span></div>
                <button *ngIf="permissions.hasCustomerSvcAccess(role) && !stateService.getActingUser()" mat-menu-item
                  [routerLink]="['/admin/view-as-user']">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/view-as-user.png"></div> View as User
                  </div>
                </button>
                <button *ngIf="stateService.getActingUser()" mat-menu-item (click)="resumeLoggedInUser()">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/view-as-user.png"></div> Resume logged-in User
                  </div>
                </button>
                <button mat-menu-item (click)="logout()">
                  <div class="d-flex align-items-center text-nowrap w-100 gap-1 mat-menu-button">
                    <div class="nav-menu-icon"><img src="assets/icons/logout.png"></div> Logout
                  </div>
                </button>
              </mat-menu>
            </ng-container>

            <ng-container *ngIf="!authed">
              <button mat-button (click)="login()">Login</button>
            </ng-container>
          </li>

          <li class="nav-item nav-item-hide">
            <select class="ml-2 form-control" #selectedLang (change)="switchLang(selectedLang.value)">
              <option *ngFor="let language of languageList" [value]="language.id"
                [selected]="language.id === translate.currentLang">
                {{ language.name }}
              </option>
            </select>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>

<div class="w-100 btm-divider"></div>