import { AfterViewChecked, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Newsfeed } from 'app/shared/models/newsfeed.model';
import { NewsfeedService } from 'app/shared/services/newsfeed/newsfeed.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss']
})
export class NewsfeedComponent implements OnInit, AfterViewChecked  {
  @ViewChild('newsfeedmodal', { static: true }) modalcc?: TemplateRef<any>;
  @ViewChild('marqueeText', { static: false }) marqueeText?: ElementRef;
  bannerNewsFeeds: Newsfeed[];
  selectedNewsFeed?: Newsfeed;
  private isWidthAdjusted = false;
  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private newsfeedService: NewsfeedService,
  ) { }

  ngOnInit(): void {

    this.newsfeedService.updateNewsfeedBanner$.subscribe((update : boolean) => {
    if (update) {
      this.newsfeedService.getBannerNewsfeeds().subscribe(bannerNewsfeedResponse => {
        this.bannerNewsFeeds = bannerNewsfeedResponse;
        this.isWidthAdjusted = false;
      }, error => {
        console.log(error);
      });
    }
  })

    this.newsfeedService.getBannerNewsfeeds().subscribe(bannerNewsfeedResponse => {
      this.bannerNewsFeeds = bannerNewsfeedResponse;
      this.isWidthAdjusted = false;
    }, error => {
      console.log(error);
    });
  }

  adjustWidth(): void {
    if (this.marqueeText) {
      const marqueeTextElement = this.marqueeText.nativeElement as HTMLElement;
      const totalWidth = Array.from(marqueeTextElement.children).reduce((acc, child) => acc + (child as HTMLElement).offsetWidth, 0);
      marqueeTextElement.style.width = `${totalWidth}px`;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isWidthAdjusted && this.bannerNewsFeeds?.length > 0) {
      this.adjustWidth();
      this.isWidthAdjusted = true;
    }
  }

  openModal(template?: TemplateRef<any>, objNewsfeed?: Newsfeed) {
    if (template) {
      this.selectedNewsFeed = objNewsfeed;
      this.modalRef = this.modalService.show(template,
        {
          class: 'modal-lg modal-dialog-centered',
          ignoreBackdropClick: true,
          backdrop: 'static',
          keyboard: false
        });
    }
  }

}
