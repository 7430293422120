import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LazyTranslateModule } from 'app/shared/lazy-translate.module';

import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NavComponent } from './components/nav/nav.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NewsfeedComponent } from './components/newsfeed/newsfeed.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    LazyTranslateModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule,
    RouterModule
  ],
  exports: [
    FooterComponent,
    NavComponent,
    NewsfeedComponent
  ],
  declarations: [
    FooterComponent,
    NavComponent,
    NewsfeedComponent
  ],
  providers: [
    BsModalService
  ]
})
export class NavModule {}
