//Angular inputs
import { Injectable } from "@angular/core";

//Model inputs
import { Role } from 'app/shared/enums/role';
import { Users } from 'app/shared/models/users.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  internalRoles: string[] = [
    Role.SuperAdmin,
    Role.Administrator,
    Role.CustomerSVC,
    Role.CustomerSvcMgr,
    Role.CustomerServiceExt,
    Role.Finance,
    Role.ExecDirector,
    Role.Marketing,
  ];

  externalRoles: string[] = [
    Role.Carrier,
    Role.BIC,
    Role.RailInc,
    Role.Border,
    Role.Reseller,
    Role.UIIA,
  ];

  scacOnlineRoles: string[] = [
    Role.SuperAdmin,
    Role.Administrator,
    Role.CustomerSVC,
    Role.CustomerSvcMgr,
    Role.CustomerServiceExt,
    Role.Finance,
    Role.ExecDirector,
    Role.Marketing,
    Role.BIC,
    Role.RailInc,
    Role.Border,
    Role.UIIA,
  ]

  constructor() {}

  hasDailyReportAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.CustomerSvcMgr || role === Role.Finance || role === Role.ExecDirector;
  }

  hasInternalAccess(role: string): boolean {
    if(this.internalRoles.indexOf(role) > -1)
      return true;
    return false;
  }

  hasCustomerAccess(role: string): boolean {
    return role === Role.Carrier;
  }

  hasFinanceAccess(role: string): boolean {
    return role === Role.Finance || role === Role.CustomerSvcMgr || role === Role.ExecDirector || role === Role.SuperAdmin;
  }

  hasManageAccountsAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.CustomerSVC || role === Role.Reseller || role === Role.CustomerServiceExt || role === Role.CustomerSvcMgr || role === Role.BIC || role === Role.RailInc;
  }

  hasCustomerSvcMgrAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.CustomerSvcMgr;
  }

  hasBusinessAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.Marketing || role === Role.ExecDirector || role === Role.Finance;
  }

  hasMarketingOrDirAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.Marketing || role === Role.ExecDirector;
  }

  hasCustomerSvcAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.CustomerServiceExt || role === Role.CustomerSVC || role === Role.CustomerSvcMgr;
  }

  hasCustomerSvcNoExt(role: string): boolean {
    return role === Role.CustomerSVC || role === Role.CustomerSvcMgr;
  }

  hasCustomerSvcNoMgr(role: string): boolean {
    return role === Role.CustomerSVC || role === Role.CustomerServiceExt;
  }

  hasResellerAccess(role: string): boolean {
    return role === Role.Reseller;
  }

  hasMgrAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.CustomerSvcMgr;
  }

  hasExternalAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.Carrier || role === Role.Reseller || role === Role.BIC || role === Role.RailInc || role === Role.Border || role === Role.UIIA;
  }

  hasOfficialsAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.BIC || role === Role.RailInc || role === Role.UIIA || role === Role.Border;
  }

  hasReservedAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.BIC || role === Role.RailInc || role === Role.UIIA;
  }

  hasCCPRRRAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.Carrier || role === Role.Reseller;
  }

  hasBorderAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.Border || role === Role.UIIA;
  }

  hasCCPAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.Carrier;
  }

  hasRRRAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.Reseller;
  }

  hasMarketingAccess(role: string): boolean {
    return role === Role.Marketing;
  }

  hasPartnerCodeAccess(role: string): boolean {
    return role === Role.BIC || role === Role.RailInc || role === Role.UIIA;
  }

  hasBicRailAccess(role: string): boolean {
    return role === Role.BIC || role === Role.RailInc;
  }

  hasRefundAccess(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.CustomerSVC || role === Role.CustomerSvcMgr;
  }

  hasManageAccountsHome(role: string): boolean {
    return role === Role.SuperAdmin || role === Role.CustomerSVC || role === Role.CustomerSvcMgr || role === Role.CustomerServiceExt || role === Role.Reseller || role === Role.BIC || role === Role.RailInc || role === Role.UIIA;
  }

  hasSuperAdminAccess(role: string): boolean{
    return role === Role.SuperAdmin;
  }

  hasScacOnlineAccess(user: Users | undefined): boolean {
    let status = false;
    if(user != undefined)
    {
      if(user?.scacOnline)
        return true;
      if(user?.roles != undefined)
        {
          user.roles.forEach(role =>{
            status  = this.checkRole(role, this.scacOnlineRoles);
            if(status)
            {
              return true;
            }
            });
        }
    }
    return status;
  }

  hasInternalAccessUser(user: Users | undefined): boolean {
    let status = false;
    if(user != undefined)
    {
      if(user?.roles != undefined)
      {
        user.roles.forEach(role =>{
          status  = this.checkRole(role, this.internalRoles);
          if(status)
          {
            return true;
          }
        });
      }
    }
    return status;
  }

  private checkRole(role: string, roles: string[])
  {
    if(roles.indexOf(role) > -1)
      return true;
    return false;
  }

}
