import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'success-dialog',
  templateUrl: 'success-dialog.component.html',
})
export class SuccessDialog {
  closeButtonText = "Close";
  message: string = "Alert info";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SuccessDialog>) {
    if(data){
        this.message = data.message || this.message;
        if (data.buttonText) {
            this.closeButtonText = this.closeButtonText;
        }
    }
  }

  onClose(): void {
    this.dialogRef.close(true);
  }
}