//Angular inputs
import { Injectable, Inject } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, from } from 'rxjs';

//Model inputs
import { Users } from 'app/shared/models/users.model';
import { ScacApplication } from 'app/shared/models/scacApplication.model';
import { StateConstants } from 'app/shared/constants/state.constants';
import { ScacApplicationConstants } from 'app/shared/constants/scac-application.constants';

//Service inputs
import { SessionStorageService } from '../state-management/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public isAuthenticated$!: Observable<boolean>;
  public role$!: Observable<string>;

  constructor(
    public authStateService: OktaAuthStateService,
    private sessionStorage: SessionStorageService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {}

  async setAuth(obs: Observable<boolean>) {
    this.isAuthenticated$ = obs;
  }

  async setRoleDirect(obs: Observable<string>) {
    this.role$ = obs;
  }

  clearSession() {
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.setRoleDirect(from(['']));
  }

  setCurrentScacApplication(ScacApp : ScacApplication) {
    this.sessionStorage.setItem(ScacApplicationConstants.ScacApp, JSON.stringify(ScacApp));
  }

  getCurrentScacApplication() {
    return JSON.parse(this.sessionStorage.getItem(ScacApplicationConstants.ScacApp));
  }

  setCurrentRequestorId(requestorId: string) {
    this.sessionStorage.setItem(ScacApplicationConstants.RequestorId, requestorId)
  }

  getCurrentRequestorId() {
    return this.sessionStorage.getItem(ScacApplicationConstants.RequestorId);
  }

  // authenticated user
  setCurrentUser(user: Users) {
    this.sessionStorage.setItem(StateConstants.User, JSON.stringify(user));
  }

  getCurrentUser() {
    return JSON.parse(this.sessionStorage.getItem(StateConstants.User));
  }

  // view as user
  setActingUser(user: Users) {
    this.sessionStorage.setItem(StateConstants.ActingUser, JSON.stringify(user));
  }

  getActingUser() {
    return JSON.parse(this.sessionStorage.getItem(StateConstants.ActingUser));
  }

  clearActingUser() {
    this.sessionStorage.removeItem(StateConstants.ActingUser);
  }

  getRole() {
    return this.getCurrentUser()?.roles[0];
  }
}
