import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-reason-dialog',
  templateUrl: 'confirm-reason-dialog.component.html',
})
export class ConfirmReasonDialog {
  message: string = "Are you sure?"
  reasonMessage: string = "Reason"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  confirmReason : string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmReasonDialog>) {
      if(data){
    this.message = data.message || this.message;
    this.reasonMessage = data.reasonMessage || this.reasonMessage;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  onConfirmClick(): void {
    var confirmDialog = {confirm : true, reason : this.confirmReason};
    this.dialogRef.close(confirmDialog);
  }

}