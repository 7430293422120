import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent {

  @Input() textToCopy: any = '';
  icon = 'content_copy';
  showCopiedText = false;

  constructor(private clipboard: Clipboard) {}

  copyText() {
    this.clipboard.copy(this.textToCopy);
    this.icon = 'check';
    this.showCopiedText = true;

    setTimeout(() => {
      this.icon = 'content_copy';
      this.showCopiedText = false;
    }, 1000);
  }

}
