import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export function amexCVVValidator(control: AbstractControl): ValidationErrors | null {
  const isAmex = (cardNumber: any) => {
    return cardNumber?.length > 1 && cardNumber?.charAt(0) === '3';
  }
  const cardNumber = control.parent?.get('cardNumber')?.value;
  if (isAmex(cardNumber) && control.value.length < 4) {
    return { amexCVVLength: { requiredLength: 4 } };
  }
  return null;
}