import { Injectable, inject } from "@angular/core";
import { ApiService } from "../api/api.service";
import { IResponse } from "app/shared/models/iresponse.model";
import { Observable, of } from "rxjs";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { PermissionsService } from "../state-management/permissions.service";
import { StateService } from "../state-management/app-state-service";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuardService {
  
  // properties
  public maintenanceModeEnabled$: Observable<boolean> = of(false);

  // injected services
  apiService: ApiService = inject(ApiService);
  stateService: StateService = inject(StateService);
  permissionsService: PermissionsService = inject(PermissionsService);
  router: Router = inject(Router);
  http: HttpClient = inject(HttpClient);

  canActivate(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.getIsMaintenanceMode()
        .subscribe({
          next: (response?: IResponse<string | boolean>) => {
            let canActivate: boolean = true;
            
            if (typeof response?.data === 'boolean') {
              canActivate = !response.data;

              if (!canActivate) {
                // If the user is logged in, we'll redirect them to the maintenance page if they are not an internal user
                const role = this.stateService.getRole();

                if (role && this.permissionsService.hasInternalAccess(role)) {
                  canActivate = true;
                } else {
                  this.router.navigate(['/']);
                }
              }
            } else {
              // Something went wrong with the check, so we'll fallback to allowing access
              canActivate = true;
            }

            this.maintenanceModeEnabled$ = of(!canActivate);
            obs.next(canActivate);
            obs.complete();
          }
        });
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

  getIsMaintenanceMode(): Observable<IResponse<boolean | string> | undefined> {
    return new Observable<IResponse<boolean | string>>((observer) => {
      this.http.get<IResponse<boolean | string>>(environment.apiBaseURL + 'misc/GetIsMaintenanceMode')
        .subscribe({
          next: (response: IResponse<boolean | string>) => { 
            if (typeof response.data === 'boolean') {
              this.maintenanceModeEnabled$ = of(response.data);
            } else {
              this.maintenanceModeEnabled$ = of(false);
            }

            observer.next(response);
            observer.complete();
          },
          error: (error: any) => {
            this.maintenanceModeEnabled$ = of(false); // We've failed to check if maintenance mode is enabled, so we'll assume it's not

            observer.error(error);
            observer.complete();
          }
        });
    });
  }

}