<ng-container *ngIf="stateService.role$ | async">
  <div class="scac-app-container">
  <app-nav 
    class="scac-app-nav"
    [authed]="authed"
    [currentUser]="currentUser"
    [role]="role">
  </app-nav>
  <div class="scac-app-content">
    @if (maintenanceService.maintenanceModeEnabled$ | async) {
      <div class="container h-100 d-flex flex-column">
        <div class="row h-100 align-content-center">
          <div class="col-12 text-center">
            <h1>Our site is currently down for maintenance.</h1>
            <h3>Please check back soon and thank you for your patience.</h3>
          </div>
        </div>
      </div>
    }
    @else {
      <router-outlet></router-outlet>
    }
  </div>
  <app-footer class="scac-app-footer"></app-footer>
</div>
</ng-container>

<ng-container *ngIf="!(stateService.role$ | async)">
  <div class="d-none">
    <router-outlet></router-outlet>
  </div>
  <app-scac-loading-spinner
    [loading]="!(stateService.role$ | async)"
  >
  </app-scac-loading-spinner>
</ng-container>