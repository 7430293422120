//Angular inputs
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

//Service inputs
import { StepService } from 'app/shared/services/step/step.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  //input/output properties
  @Input() steps: any;
  @Output() navigateToPathEvent = new EventEmitter<string>();

  //private/public properties
  currentStep: string;
  stepSubscription: Subscription;
  stepIndex: any;
  progressBar: any = 0;
  progressTotal: number;

  constructor(
    private stepService: StepService,
    private cdRef: ChangeDetectorRef
  ) {
    this.stepSubscription = this.stepService
      .getCurrentStep()
      .subscribe((step: string) => {
        this.currentStep = step;
        this.stepIndex = this.steps.findIndex(
          (obj: any) => obj['route'] === step
          );
        this.progressBar = (100 / (this.steps.length - 1)) * this.stepIndex || 0;
        this.cdRef.detectChanges(); 
      });
  }

  ngOnInit(): void {}

  navigateToPath(path: string) {
    if (this.isFormValid(path)) {
      this.navigateToPathEvent.emit(path);
    }
  }

  isFormValid(path: string): boolean {
    return true;
  }

  ngOnDestroy(): void {
    this.stepSubscription.unsubscribe();
  }
}
