// Angular inputs
import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

// Model inputs
import { StateConstants } from "app/shared/constants/state.constants";

// Service inputs
import { SessionStorageService } from "../state-management/session-storage.service";

@Injectable({
  providedIn: 'root'
})
export class ActiveUserGuardService {
  
  private sessionStorage: SessionStorageService = inject(SessionStorageService);
  private router: Router = inject(Router);

  canActivate(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
      let canActivate: boolean = this.getUserActive();

      if (!canActivate) {
        // If the user is not active, we'll redirect them to the inactive page
        this.router.navigate(['/inactive-user']);
      }

      obs.next(canActivate);
      obs.complete();
    });
  }
  
  canActivateChild(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }

  getUserActive(): boolean {
    let result = true;
    const userJson = this.sessionStorage.getItem(StateConstants.User);
    
    if (userJson) {
      const user = JSON.parse(userJson);
      result = user.isActive;
    }

    return result;
  }
  
}