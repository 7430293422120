//Angular inputs
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Mat inputs
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

//Custom component inputs
import { ScacLoadingSpinnerComponent } from './scac-loading-spinner.component';

@NgModule({
  imports: [
    //Angular inputs
    CommonModule,

    //Mat inputs
    MatProgressSpinnerModule
  ],
  declarations: [ScacLoadingSpinnerComponent],
  exports: [ScacLoadingSpinnerComponent]
})

export class ScacLoadingSpinnerModule {}
