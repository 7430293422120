import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { MaintenanceGuardService } from './shared/services/guard/maintenance-guard.service';
import { CustomCallbackComponent } from './shared/components/custom-okta-callback/custom-okta-callback.component';
import { ActiveUserGuardService } from './shared/services/guard/active-user-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [ActiveUserGuardService],
    canActivateChild: [MaintenanceGuardService, ActiveUserGuardService],
    loadChildren: () => import('./public/public-routing.module').then(m => m.PublicRoutingModule)
  },
  { 
    path: 'login/callback', 
    component: CustomCallbackComponent
  },
  {
    path: 'admin',
    canActivate: [OktaAuthGuard, MaintenanceGuardService, ActiveUserGuardService],
    canActivateChild: [MaintenanceGuardService, ActiveUserGuardService],
    loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule)
  },
  {
    path: 'reseller',
    canActivate: [MaintenanceGuardService],
    canActivateChild: [MaintenanceGuardService],
    loadChildren: () => import('./reseller/reseller-routing.module').then(m => m.ResellerRoutingModule)
  },
  {
    path: 'reports',
    canActivate: [OktaAuthGuard, MaintenanceGuardService, ActiveUserGuardService],
    canActivateChild: [MaintenanceGuardService, ActiveUserGuardService],
    loadChildren: () => import('./reports/reports-routing.module').then(m => m.ReportsRoutingModule)
  },
  {
    path: 'scac',
    canActivate: [MaintenanceGuardService, ActiveUserGuardService],
    canActivateChild: [MaintenanceGuardService, ActiveUserGuardService],
    loadChildren: () => import('./scac/scac-routing.module').then(m => m.ScacRoutingModule)
  },
  {
    path: 'login',
    canActivate: [OktaAuthGuard],
    loadChildren: () => import('./public/public-routing.module').then(m => m.PublicRoutingModule)
  },
  {
    path: 'inactive-user',
    loadChildren: () => import('./public/components/inactive-user/inactive-user.module').then(m => m.InactiveUserModule)
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
