import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessages {
  private errorMessagesSubject = new BehaviorSubject<{ [key: string]: string | ((error: any) => string) }>({});
  errorMessages$ = this.errorMessagesSubject.asObservable();
  
  constructor(private translateSvc: TranslateService) {
    this.loadErrorMessages();

    this.translateSvc.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadErrorMessages();
    });
  }

  // Returns a single FormValidators x Messages mapping object."
  private loadErrorMessages() {
    return forkJoin({
      required: this.translateSvc.get('Generic_RequiredFieldError'),
      minlength: this.translateSvc.get('Generic_MinLengthError'),
      maxlength: this.translateSvc.get('Generic_MaxLengthError'),
      email: this.translateSvc.get('Generic_EmailError'),
      min: this.translateSvc.get('Generic_MinError'),
      max: this.translateSvc.get('Generic_MaxError'),
      noWhiteSpaces: this.translateSvc.get('Generic_InvalidValueError'),
      specialCharacters: this.translateSvc.get('Generic_InvalidValueError'),
      amexCVVLength: this.translateSvc.get('Generic_MinLengthError'),
      creditCardError: this.translateSvc.get('Generic_InvalidValueError'),
      pattern: this.translateSvc.get('Generic_InvalidValueError'),
      mask: this.translateSvc.get('Generic_InvalidValueError'),
      expiredMonth: this.translateSvc.get('Generic_ExpiredMonth'),
      expiredYear: this.translateSvc.get('Generic_ExpiredYear'),
      invalidPhone: this.translateSvc.get('Generic_InvalidValueError'),
    }).pipe(
      map((translations: { [key: string]: string }) => {
        return {
          required: translations['required'],
          minlength: (error: any) => `${translations['minlength']} ${error?.requiredLength}`,
          maxlength: (error: any) => `${translations['maxlength']} ${error?.requiredLength}`,
          email: translations['email'],
          min: (error: any) => `${translations['min']} ${error?.min}`,
          max: (error: any) => `${translations['max']} ${error?.max}`,
          noWhiteSpaces: translations['noWhiteSpaces'],
          specialCharacters: translations['specialCharacters'],
          amexCVVLength: (error: any) => `${translations['amexCVVLength']} ${error?.requiredLength}`,
          creditCardError: translations['creditCardError'],
          pattern: translations['pattern'],
          mask: translations['mask'],
          expiredMonth: translations['expiredMonth'],
          expiredYear: translations['expiredYear'],
          invalidPhone: translations['invalidPhone'],
        };
      })
    ).subscribe((messages) => {
      this.errorMessagesSubject.next(messages);
    });
  }

  getErrorMessages(): Observable<{ [key: string]: string | ((error: any) => string) }> {
    return this.errorMessages$;
  }
}
