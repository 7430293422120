import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {

    transform(rawNum: string | undefined): string {
      if (!!rawNum) {
        let num = rawNum as string;
        const hasPlusSign = num.startsWith("+");
        const numericOnly = num.replace(/\D/g, "");

        if (numericOnly.length === 10 && !hasPlusSign) {
          const areaCodeStr = numericOnly.slice(0, 3);
          const midSectionStr = numericOnly.slice(3, 6);
          const lastSectionStr = numericOnly.slice(6, 10);
          return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
        }

        return num;
      }
      return '';
    }
}
