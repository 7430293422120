<div class="marquee w-100 fw-bold text-capitalize mr-3 text-nowrap overflow-hidden">
  <div class="inner w-100 d-flex align-items-center">
    <div class="banner-container">
      <p #marqueeText class="text m-0 px-1 feed-container">
        <span class="rounded-pill py-1 px-3 mx-1" *ngFor="let newsfeed of bannerNewsFeeds" (click)="openModal(modalcc , newsfeed)">{{newsfeed.title}}</span>
      </p>
    </div>
  </div>
</div>

<ng-template #newsfeedmodal>
  <div class="modal-header p-3">
    <h1>{{selectedNewsFeed ? selectedNewsFeed!.title : "Newsfeed Heading"}}</h1>
    <button mat-raised-button class="btn bg-danger close-btn" aria-label="Close" (click)="modalRef.hide()">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>

  <div class="modal-body p-3">
    <h5>Description</h5>
    <br>
    <span *ngIf="selectedNewsFeed" [innerHTML]="selectedNewsFeed!.body"></span>
  </div>

</ng-template>