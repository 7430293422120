import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  public stateLabelText: string;
  public isAddressRequired: boolean = false;
  constructor() {}

  updateAddressRequiredFields(form: FormGroup): void {
    const countryControl = form.get('countryCode') || form.get('country')
    const stateControl = form.get('state');
    const postalCodeControl = form.get('postalCode');
    const address1Control = form.get('address1');
    const cityControl = form.get('city');
    this.stateLabelText = 'Generic_StateIntlLabel';
    
    if (countryControl) {
      countryControl?.setValidators([Validators.required]);
      address1Control?.setValidators([Validators.required]);
      cityControl?.setValidators([Validators.required]);

      countryControl.valueChanges.subscribe((value) => {
          this.isAddressRequired = value === 'USA' || value === 'CAN';
          const isDistrictProvince = value=== 'CHN' || value ==='VNM';

          this.stateLabelText = this.isAddressRequired ? 'Generic_StateLabel' : (isDistrictProvince ? 'Generic_DistrictProvince' : 'Generic_StateIntlLabel');
   
          if(this.isAddressRequired){
          const currentStateValue = stateControl?.value;
          if (currentStateValue && currentStateValue.length > 2) {
            stateControl?.setValue(currentStateValue.substring(0, 2));
          }
          stateControl?.setValidators([Validators.required]);
          postalCodeControl?.setValidators([Validators.required]);
        } 
        else {     
          this.isAddressRequired = false;   
          stateControl?.setValidators([]);
          postalCodeControl?.setValidators([]);
        }

        form?.updateValueAndValidity();
      });
    }
  }
}
