import { FormControl, ValidationErrors } from '@angular/forms';

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
const amexpRegEx = /^(?:3[47][0-9]{13})$/;
const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

export function creditCardValidator(
  control: FormControl
): ValidationErrors | null {
  const value = control.value?.replace(/\s/g, ''); // Remove spaces
  
  // Check if the card number is empty
  if (!value) {
    return { creditCardError: true };
  }

  // Check if the card number starts with an invalid digit
  const startingDigit = value.charAt(0);
  const validStartingDigits = ['3', '4', '5', '6'];
  if (!validStartingDigits.includes(startingDigit)) {
    return { creditCardError: true };
  }

  // Check if the card number has an invalid length
  const cardLength = value.length;
  const validLengths = [15, 16];
  if (!validLengths.includes(cardLength)) {
    return { creditCardError: true };
  }

  // Check against regex patterns for specific card brands
  if (
    !visaRegEx.test(value) &&
    !mastercardRegEx.test(value) &&
    !amexpRegEx.test(value) &&
    !discovRegEx.test(value)
  ) {
    return { creditCardError: true };
  }

  // Perform Luhn checksum validation
  let sum = 0;
  let isEven = false;
  for (let i = cardLength - 1; i >= 0; i--) {
    let digit = parseInt(value.charAt(i), 10);
    if (isEven) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    isEven = !isEven;
  }

  if (sum % 10 !== 0) {
    return { creditCardError: true };
  }

  // If all checks pass, return null (no error)
  return null;
}
