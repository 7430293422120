import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[uppercase]'
})
export class UppercaseDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    input.value = input.value.toUpperCase();
    if (this.control && this.control.control) {
      this.control.control.setValue(input.value);
    }

    if (['text', 'tel', 'textarea'].includes(input.type)) {
      input.setSelectionRange(start, end);
    }
  }
}
