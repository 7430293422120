//Angular inputs
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    public httpClient: HttpClient
    // Add references to make this work 
    //@Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) { }
}

