<footer class="scac-footer text-white">
  <div class="container-footer">
    <div class="row mx-4">
      <div class="column-footer col-12 col-sm-6 col-md-6 col-lg-3">
        <ul>
          <li>
            <a href="https://nmfta.org">
              <img class="nmfta-logo" src="./assets/nmfta-logo.svg">
            </a>
          </li>
          <li class="font-80 mt-3 column-box">
            1001 N Fairfax Street Suite 600 Alexandria, Virginia 22314-1798 (866) 411-6632
          </li>
        </ul>
      </div>
      <div class="column-footer col-12 col-sm-6 col-md-6 col-lg-3">
        <ul>
          <li><strong>NMFTA Products</strong></li>
          <li><a href="https://classit.nmfta.org/all/Welcome.aspx?x=1" target="_blank">ClassIT</a></li>
          <li><a href="https://splc.nmfta.org/All/Login.aspx?ReturnUrl=%2f" target="_blank">SPLC Online</a></li>
          <li><a href="https://store.nmfta.org/" target="_blank">Store (Non-SCAC Purchase)</a></li>
          <li><a href="https://usermanager.nmfta.org/All/Login.aspx?ReturnUrl=%2f" target="_blank">User Manager
              (Non-SCAC Users)</a></li>
        </ul>
      </div>
      <div class="column-footer col-12 col-sm-6 col-md-6 col-lg-3">
        <ul>
          <li><strong>SCAC<sup>&reg;</sup> Resources</strong></li>
          <li><a href="https://nmfta.org/scac/history/" target="_blank">History</a></li>
          <li><a href="https://nmfta.org/scac/product-descriptions/" target="_blank">Product Description</a></li>
          <li><a href="https://nmfta.org/scac/scac-ip/" target="_blank">Intellectual Property Rights</a></li>
          <li><a href="https://nmfta.org/scac-resources/" target="_blank">Documentation and Videos</a></li>
        </ul>
      </div>
      <div class="column-footer col-12 col-sm-6 col-md-6 col-lg-3">
        <ul>
          <li><strong><a href="https://nmfta.org/support/" target="_blank">Contact Support</a></strong></li>
          <li class="mt-4"><strong><a href="https://nmfta.org/" target="_blank">NMFTA Home</a></strong></li>
          <li class="d-flex mt-4 justify-content-between px-1 column-box">
            <a href="https://www.facebook.com/NMFTA" target="_blank"><img class="social-images-footer" src="./assets/icons/facebook.svg"></a>
            <a href="https://twitter.com/nmfta" target="_blank"><img class="social-images-footer" src="./assets/icons/xtwitter.svg"></a>
            <a href="https://www.linkedin.com/company/national-motor-freight-traffic-association-inc./"
              target="_blank"><img class="social-images-footer" src="./assets/icons/linkedin.svg"></a>
            <a href="https://instagram.com/nmftanews" target="_blank"><img class="social-images-footer" src="./assets/icons/instagram.svg"></a>
            <a href="https://www.youtube.com/@nmftassoc" target="_blank"><img class="social-images-footer" src="./assets/icons/youtube.svg"></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row text-center font-80 mx-4">
      <div class="col-12">
        <p class="m-0 pb-4 pt-3 manifest-link">Shipping across the Border? Click <a href="https://info.nmfta.org/emanifest-request">here</a> to secure your e-Manifest.</p>
        <p class="m-0">All content &copy; Copyright {{currentYear}} National Motor Freight Traffic Association,
          Inc.<sup>&trade;</sup> All Rights Reserved.</p>
        <p class="mt-2">
          <a href="https://nmfta.org/privacy/" class="me-1">Privacy Policy</a> |
          <a href="https://nmfta.org/terms-of-use/" class="mx-1">Terms of Use</a> |
          <a href="https://nmfta.org/terms-of-sale/" class="mx-1">Terms of Sale</a> |
          <a href="mailto:customersupport@nmfta.org" class="ms-1">Feedback</a>
        </p>
      </div>
    </div>
  </div>
</footer>