//Angular inputs
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

//Mat inputs
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';

//Custom component inputs
import { ScrollComponent } from './components/scroll/scroll.component';
import { StepsComponent } from './components/steps/steps.component';
import { PhonePipe } from './pipes/phone.pipe';
import { PaymentComponent } from './components/organisms/payment/payment.component';
import { NgxMaskModule } from 'ngx-mask';
import { UppercaseModule } from './directives/uppercase.module';
import { InputWrapperComponent } from './components/molecules/input-wrapper/input-wrapper.component';
import { LazyTranslateModule } from './lazy-translate.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorMessages } from './services/error-messages/error-messages.service';
import { CopyComponent } from './components/atoms/copy/copy.component';

@NgModule({
  imports: [
    //Angular inputs
    CommonModule,
    ReactiveFormsModule,

    // Custom inputs
    LazyTranslateModule,
    NgxMaskModule,
    UppercaseModule,
    
    //Mat inputs
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatRadioModule
  ],
  exports: [
    ScrollComponent, 
    PaymentComponent,
    PhonePipe, 
    InputWrapperComponent, 
    StepsComponent,
    CopyComponent,
    UppercaseModule
  ],
  declarations: [
    ScrollComponent, 
    PaymentComponent,
    PhonePipe, 
    StepsComponent,
    InputWrapperComponent,
    CopyComponent
  ],
  providers: [
    ErrorMessages
  ]
})
export class SharedModule {}
